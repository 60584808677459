module.exports = {
  seo: {
    title: "פרוייקטים - Jenko",
    description: "",
  },
  intro: {
    title: "פרוייקטים",
    desc: "חברת ג'נקו עוסקת בפיתוח ותכנון מוצרים בהתאמה לצרכים המשתנים של לקוחותיה\u00A0המגוונים",
  },
  projects: [
    {
      title: "טורבינה חשמלית",
      desc: "ליצירת לחץ אוויר",
    },
    {
      title: "מערכת נגד שכחת ילדים\u00A0ברכב",
      desc: "מערכת בעלת התקנה קלה ברכב, המתריעה לנהג ע״י שליחת הודעה לסלולר כי שכח את\u00A0הילד\u00A0ברכב",
    },
    {
      title: "קייס תצוגה מאובזר בתאורה\u00A0פנימית\u00A0לגיטרה",
      desc: "מערכת מנשא יוקרתית לגיטרה, המאפשרת מצד אחד לנייד את הגיטרה בצורה נוחה וחדשנית מצד שני להציג את הגיטרה\u00A0כתצוגה",
    },
    {
      title: "רולר גרין",
      desc: "רולר לתיקוני צבע. קל לניקוי לאחר שימוש, במצב סגור הצבע לא\u00A0מתייבש",
    },
    {
      title: "אולר מודרני",
      desc: "ערכה המשלבת הטענה מנייד לנייד, סוללה ניידת חיבורי הטענה של כל סוגי הניידים, מברג, פותחן בקבוקים / קופסאות, סכין\u00A0ופנס",
    },
    {
      title: "מערכת לזיהוי ירי",
      desc: "מערכת לזיהוי ירי בזמן אמת המתריעה על הירי ומיקומו כאשר מתבצע בסמוך לגלאי",
    },
    {
      title: "מברגה מקדחה משולב\u00A0משחזה",
      desc: "שילוב של משחזה המתאימה לכל מקדחה / מברגה על ידי מתאם\u00A0מיוחד",
    },
    {
      title: "מגנון חיתוך קטטר בחירום",
      desc: "מנגנון המונע החלקה של הקטטר ומבצע חיתוך בעת משיכת\u00A0חירום",
    },
    {
      title: "טבעת חכמה",
      desc: "טבעת משולבת נורות לד המתריעה על התרחקות מהנייד במקרה של אובדן / גניבה / שיכחה של הנייד על ידי מנגנון\u00A0רטט",
    },
    {
      title: "מתאם לשלט של אפל TV",
      desc: "מתאם שמעניק נוכחות ונפח למניעת איבוד של השלט הקטן, מגן עליו בנפילות, המדמה אחיזת ג'ויסטיק של קונסולת\u00A0משחקים",
    },
    {
      title: "מערכת לזיהוי מכשולים",
      desc: "מערכת אלקטרונית מותקנת בכיסוי של טלפון נייד ומתריעה על מכשולים בדרך, בזמן בו המשתמש עם העיניים לסלולר",
    },
    {
      title: "מגלגל סיגריות אלקטרוני",
      desc: "מגלגל סיגריות חשמלי ואוטומטי שלא מצריך חיבור לחשמל, ניתן לגלגל מספר רב של סיגריות באופן\u00A0אוטומטי",
    },
  ],
  outro:
    "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולהע צופעט למרקוח איבן איף, ברומץ כלרשט מיחוצים. קלאצי סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו.",
  dir: "rtl",
};
