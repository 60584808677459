import * as React from "react";
import Layout from "../../layouts/he";
import PageHead from "../../components/shared/PageHead";
import content from "../../data/content/projects/projects-he";
import * as styles from "../../styles/pages/projects/index.module.css";
import Intro from "../../components/shared/Intro";
import ProjectsGrid from "../../components/pages/ProjectsGrid";

const Projects = (props) => {
  const { seo, intro, projects, dir } = content;
  const { sIntroSection, sIntro, sIntroDesc } = styles;
  return (
    <Layout location={props.location} lang="he">
      <PageHead pageHeadData={seo} />
      <article lang="he" className="projects" dir={dir}>
        <Intro content={intro} styles={{ sIntroSection, sIntro, sIntroDesc }} />
        <ProjectsGrid projects={projects} dir={dir} />
      </article>
    </Layout>
  );
};

export default Projects;
